<script src="./index.js"></script>
<style src="./style.scss" lang="scss" scoped/>
<template>
  <div v-if="isLoading" class="mt-5">
    <PagePreloader layout="product"/>
  </div>
  <div id="admin-add-product" v-else>
    <div class="container">
      <div class="row ">
        <div class="col-md-8 ">
          <div class="add-property-title mb-5"> {{ $t('vendor-product-details') }}</div>
          <div class="row">
            <div class="col-md-12 input-item">
              <div class="d-flex align-items-center">
                <svg width="16" height="11" viewBox="0 0 16 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M10.2578 0.40625V3.27344H9.97656C9.8099 2.61198 9.625 2.13802 9.42188 1.85156C9.21875 1.5599 8.9401 1.32812 8.58594 1.15625C8.38802 1.0625 8.04167 1.01562 7.54688 1.01562H6.75781V9.1875C6.75781 9.72917 6.78646 10.0677 6.84375 10.2031C6.90625 10.3385 7.02344 10.4583 7.19531 10.5625C7.3724 10.6615 7.61198 10.7109 7.91406 10.7109H8.26562V11H2.71875V10.7109H3.07031C3.3776 10.7109 3.625 10.6562 3.8125 10.5469C3.94792 10.474 4.05469 10.349 4.13281 10.1719C4.1901 10.0469 4.21875 9.71875 4.21875 9.1875V1.01562H3.45312C2.73958 1.01562 2.22135 1.16667 1.89844 1.46875C1.44531 1.89062 1.15885 2.49219 1.03906 3.27344H0.742188V0.40625H10.2578Z"
                    fill="#FF7A00"/>
                  <path
                    d="M10.9448 11V10.7651C11.1987 10.7651 11.3722 10.6995 11.4653 10.5684C11.5627 10.4372 11.6113 10.1769 11.6113 9.7876V5.46484H11.021C10.6063 5.46484 10.3185 5.5389 10.1577 5.68701C9.99691 5.83512 9.86995 6.14404 9.77686 6.61377H9.54199V5.06494H15.458V6.61377H15.2231C15.147 6.19059 15.0348 5.89437 14.8867 5.7251C14.7386 5.5516 14.4403 5.46484 13.9917 5.46484H13.3887V9.7876C13.3887 10.1896 13.4352 10.452 13.5283 10.5747C13.6257 10.6974 13.8013 10.7609 14.0552 10.7651V11H10.9448Z"
                    fill="#FF7A00"/>
                </svg>
                <div class="input-title-item"> {{ $t('vendor-product-title') }}</div>
              </div>
              <div>
                <Input :inputtype="'text'" v-model="product.title" :content="product.title"
                       :error="fieldErrors.includes('title')"/>
              </div>
            </div>
            <div class="col-md-12 input-item">
              <div class="d-flex align-items-center">
                <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <g clip-path="url(#clip0_121_11009)">
                    <path
                      d="M6.96707 14C4.96519 14 2.9699 14 0.968015 14C0.237065 14 0 13.7695 0 13.0452C0 9.01505 0 4.98495 0 0.954845C0 0.24365 0.237065 0 0.935089 0C4.97836 0 9.02164 0 13.0649 0C13.7498 0 14 0.24365 14 0.921919C14 4.97836 14 9.02822 14 13.0847C14 13.7629 13.7498 14.0066 13.0649 14.0066C11.0301 14.0066 9.00188 14 6.96707 14ZM12.571 12.5776C12.571 9.77234 12.571 7 12.571 4.22107C8.84384 4.22107 5.13641 4.22107 1.42897 4.22107C1.42897 7.01975 1.42897 9.7921 1.42897 12.5776C5.14958 12.5776 8.85042 12.5776 12.571 12.5776ZM12.5776 1.42897C8.83725 1.42897 5.14299 1.42897 1.42897 1.42897C1.42897 1.88993 1.42897 2.33114 1.42897 2.77893C5.15616 2.77893 8.86359 2.77893 12.5776 2.77893C12.5776 2.31797 12.5776 1.88335 12.5776 1.42897Z"
                      fill="#FF7A00"/>
                    <path
                      d="M6.9999 6.29532C8.13254 6.29532 9.27177 6.29532 10.4044 6.29532C10.7205 6.29532 10.9839 6.40068 11.1288 6.70359C11.2539 6.967 11.2407 7.25675 11.03 7.42796C10.8456 7.57942 10.569 7.68478 10.332 7.69137C8.65935 7.71112 6.99331 7.70454 5.32069 7.70454C4.72802 7.70454 4.14195 7.71112 3.54929 7.69795C3.24637 7.69137 3.00272 7.57283 2.87102 7.2765C2.75248 7.00651 2.77882 6.72335 2.98955 6.55214C3.16735 6.40726 3.43075 6.30849 3.66123 6.3019C4.77412 6.28215 5.88701 6.29532 6.9999 6.29532Z"
                      fill="#FF7A00"/>
                    <path
                      d="M5.24153 10.4904C4.67521 10.4904 4.1023 10.4969 3.53598 10.4904C3.10794 10.4838 2.81161 10.2072 2.79844 9.81867C2.79186 9.42356 3.10136 9.10748 3.53598 9.10748C4.68179 9.10089 5.82761 9.10089 6.98 9.10748C7.39487 9.10748 7.69778 9.41698 7.69778 9.79892C7.69778 10.1874 7.39487 10.4838 6.98 10.4838C6.40051 10.4969 5.82102 10.4904 5.24153 10.4904Z"
                      fill="#FF7A00"/>
                  </g>
                  <defs>
                    <clipPath id="clip0_121_11009">
                      <rect width="14" height="14" fill="white"/>
                    </clipPath>
                  </defs>
                </svg>
                <div class="input-title-item"> {{ $t('vendor-short-description') }}</div>
              </div>
              <div>
                <TextArea
                  :label="$t('enter-short-description')"
                  v-model="product.shortDescription"
                  :content="product.shortDescription"
                  :error="fieldErrors.includes('shortDescription')"
                />
              </div>
            </div>
            <div class="col-md-12 input-item">
              <div class="d-flex align-items-center">
                <svg width="19" height="20" viewBox="0 0 19 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <g clip-path="url(#clip0_141_11469)">
                    <rect width="14" height="14" fill="white"/>
                    <path
                      d="M6.96707 14C4.96519 14 2.9699 14 0.968015 14C0.237065 14 0 13.7695 0 13.0452C0 9.01505 0 4.98495 0 0.954845C0 0.24365 0.237065 0 0.935089 0C4.97836 0 9.02164 0 13.0649 0C13.7498 0 14 0.24365 14 0.921919C14 4.97836 14 9.02822 14 13.0847C14 13.7629 13.7498 14.0066 13.0649 14.0066C11.0301 14.0066 9.00188 14 6.96707 14ZM12.571 12.5776C12.571 9.77234 12.571 7 12.571 4.22107C8.84384 4.22107 5.13641 4.22107 1.42897 4.22107C1.42897 7.01975 1.42897 9.7921 1.42897 12.5776C5.14958 12.5776 8.85042 12.5776 12.571 12.5776ZM12.5776 1.42897C8.83725 1.42897 5.14299 1.42897 1.42897 1.42897C1.42897 1.88993 1.42897 2.33114 1.42897 2.77893C5.15616 2.77893 8.86359 2.77893 12.5776 2.77893C12.5776 2.31797 12.5776 1.88335 12.5776 1.42897Z"
                      fill="#FF7A00"/>
                  </g>
                  <g clip-path="url(#clip1_141_11469)">
                    <rect width="14" height="14" transform="translate(3 6)" fill="white"/>
                    <path
                      d="M9.96707 20C7.96519 20 5.9699 20 3.96802 20C3.23706 20 3 19.7695 3 19.0452C3 15.0151 3 10.9849 3 6.95484C3 6.24365 3.23706 6 3.93509 6C7.97836 6 12.0216 6 16.0649 6C16.7498 6 17 6.24365 17 6.92192C17 10.9784 17 15.0282 17 19.0847C17 19.7629 16.7498 20.0066 16.0649 20.0066C14.0301 20.0066 12.0019 20 9.96707 20ZM15.571 18.5776C15.571 15.7723 15.571 13 15.571 10.2211C11.8438 10.2211 8.13641 10.2211 4.42897 10.2211C4.42897 13.0198 4.42897 15.7921 4.42897 18.5776C8.14958 18.5776 11.8504 18.5776 15.571 18.5776ZM15.5776 7.42897C11.8373 7.42897 8.14299 7.42897 4.42897 7.42897C4.42897 7.88993 4.42897 8.33114 4.42897 8.77893C8.15616 8.77893 11.8636 8.77893 15.5776 8.77893C15.5776 8.31797 15.5776 7.88335 15.5776 7.42897Z"
                      fill="#FF7A00"/>
                    <path
                      d="M9.9999 12.2953C11.1325 12.2953 12.2718 12.2953 13.4044 12.2953C13.7205 12.2953 13.9839 12.4007 14.1288 12.7036C14.2539 12.967 14.2407 13.2567 14.03 13.428C13.8456 13.5794 13.569 13.6848 13.332 13.6914C11.6594 13.7111 9.99331 13.7045 8.32069 13.7045C7.72802 13.7045 7.14195 13.7111 6.54929 13.698C6.24637 13.6914 6.00272 13.5728 5.87102 13.2765C5.75248 13.0065 5.77882 12.7234 5.98955 12.5521C6.16735 12.4073 6.43075 12.3085 6.66123 12.3019C7.77412 12.2821 8.88701 12.2953 9.9999 12.2953Z"
                      fill="#FF7A00"/>
                    <path
                      d="M8.24153 16.4904C7.67521 16.4904 7.1023 16.4969 6.53598 16.4904C6.10794 16.4838 5.81161 16.2072 5.79844 15.8187C5.79186 15.4236 6.10136 15.1075 6.53598 15.1075C7.68179 15.1009 8.82761 15.1009 9.98 15.1075C10.3949 15.1075 10.6978 15.417 10.6978 15.7989C10.6978 16.1874 10.3949 16.4838 9.98 16.4838C9.40051 16.4969 8.82102 16.4904 8.24153 16.4904Z"
                      fill="#FF7A00"/>
                  </g>
                  <defs>
                    <clipPath id="clip0_141_11469">
                      <rect width="14" height="14" fill="white"/>
                    </clipPath>
                    <clipPath id="clip1_141_11469">
                      <rect width="14" height="14" fill="white" transform="translate(3 6)"/>
                    </clipPath>
                  </defs>
                </svg>
                <div class="input-title-item"> {{ $t('vendor-full-description') }}</div>
              </div>
              <div>
                <TextArea
                  :label="$t('enter-full-description')"
                  v-model="product.description"
                  :content="product.description"
                  :error="fieldErrors.includes('description')"
                />
              </div>
            </div>

            <div class="col-md-12 input-item">
              <div class="d-flex align-items-center">
                <svg width="15" height="20" viewBox="0 0 15 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <g clip-path="url(#clip0_121_11035)">
                    <path
                      d="M3.73431 2.5126C3.74215 2.40301 3.75785 2.34039 3.75 2.27777C3.67939 1.57327 3.87552 1.1897 4.8091 1.26015C4.83264 1.26015 4.86402 1.26015 4.88755 1.26015C5.36611 1.29147 5.71914 1.2445 6.06433 0.743521C6.841 -0.375853 8.48065 -0.203642 9.13964 1.00967C9.25732 1.22102 9.38285 1.27581 9.59467 1.26798C9.87709 1.25233 10.1674 1.26015 10.4498 1.26798C11.0303 1.26798 11.2422 1.48716 11.25 2.06642C11.25 2.19949 11.25 2.32473 11.25 2.48912C11.3755 2.49694 11.4854 2.5126 11.5952 2.5126C12.2228 2.5126 12.8426 2.5126 13.4702 2.5126C14.545 2.5126 15 2.96661 15 4.0625C15 5.97248 15 7.88246 15 9.80027C15 12.7122 15 15.6241 15 18.5361C15 19.5146 14.5136 19.9999 13.5408 19.9999C9.50837 19.9999 5.4681 19.9999 1.43567 19.9999C0.486402 19.9999 0 19.5067 0 18.5517C0 13.6907 0 8.8218 0 3.96074C0 3.00575 0.486402 2.5126 1.43567 2.5126C2.18881 2.5126 2.94195 2.5126 3.73431 2.5126ZM13.7291 18.7318C13.7291 13.7298 13.7291 8.75135 13.7291 3.7807C12.8975 3.7807 12.0973 3.7807 11.2578 3.7807C11.2578 3.9764 11.2657 4.15644 11.2578 4.33648C11.2422 4.767 11.0068 5.00966 10.5675 5.00966C8.51987 5.01749 6.48013 5.01749 4.43253 5.00966C4.00105 5.00966 3.77354 4.75918 3.75785 4.32082C3.75 4.14078 3.75785 3.96857 3.75785 3.78853C2.89487 3.78853 2.08682 3.78853 1.27092 3.78853C1.27092 8.78266 1.27092 13.7455 1.27092 18.7318C5.42103 18.7318 9.56329 18.7318 13.7291 18.7318ZM9.97908 3.74156C9.97908 3.31103 9.97908 2.92747 9.97908 2.50477C9.59467 2.50477 9.23379 2.50477 8.87291 2.50477C8.39435 2.49695 8.20607 2.33256 8.12762 1.84724C8.06485 1.47933 7.83734 1.26015 7.51569 1.26015C7.1705 1.25233 6.94299 1.4715 6.88023 1.87072C6.80962 2.32473 6.60565 2.50477 6.15063 2.5126C5.77406 2.52043 5.40533 2.5126 5.02877 2.5126C5.02877 2.95096 5.02877 3.35017 5.02877 3.74939C6.69195 3.74156 8.32374 3.74156 9.97908 3.74156Z"
                      fill="#FF7A00"/>
                    <path
                      d="M4.34642 9.78477C5.04464 9.08027 5.67225 8.43839 6.31556 7.82C6.46462 7.6791 6.69213 7.52254 6.88041 7.5382C7.07654 7.55385 7.29621 7.72606 7.42173 7.89045C7.60217 8.13311 7.48449 8.39142 7.29621 8.59495C6.94317 8.96285 6.5823 9.3151 6.22142 9.67518C5.78993 10.1057 5.35845 10.5441 4.91912 10.9746C4.52686 11.3582 4.22874 11.366 3.84433 10.9902C3.46776 10.6302 3.09903 10.2623 2.73815 9.88653C2.43219 9.56559 2.4165 9.22117 2.68324 8.95502C2.94997 8.68888 3.30301 8.70454 3.61681 9.00982C3.86002 9.23683 4.07968 9.49514 4.34642 9.78477Z"
                      fill="#FF7A00"/>
                    <path
                      d="M4.36198 14.7633C4.9896 14.1293 5.57799 13.5422 6.16638 12.9551C6.26836 12.8533 6.36251 12.7438 6.48018 12.6576C6.76261 12.4385 7.09211 12.4541 7.32747 12.6968C7.56282 12.9394 7.57851 13.2604 7.34316 13.5422C7.1941 13.7222 7.0215 13.8788 6.85675 14.0432C6.22914 14.6694 5.60937 15.2956 4.98175 15.9218C4.53458 16.368 4.25215 16.368 3.78928 15.914C3.4441 15.5774 3.10675 15.2408 2.76941 14.8886C2.44776 14.552 2.43207 14.1919 2.71449 13.9257C2.98908 13.6753 3.32642 13.6909 3.64807 14.004C3.87558 14.2389 4.10309 14.4894 4.36198 14.7633Z"
                      fill="#FF7A00"/>
                    <path
                      d="M10.6614 8.75934C11.0537 8.75934 11.4459 8.75151 11.8304 8.75934C12.2305 8.76717 12.4972 9.01766 12.505 9.36991C12.5129 9.72216 12.2462 9.99613 11.846 9.99613C11.038 10.004 10.2299 10.004 9.42973 9.99613C9.02962 9.9883 8.76289 9.73781 8.75504 9.38556C8.7472 9.01766 9.02962 8.76717 9.44542 8.75934C9.85337 8.75151 10.2535 8.75934 10.6614 8.75934Z"
                      fill="#FF7A00"/>
                    <path
                      d="M10.6225 14.998C10.2303 14.998 9.83801 15.0059 9.45359 14.998C9.02995 14.9902 8.75537 14.7397 8.75537 14.3718C8.75537 14.0039 9.02995 13.7534 9.46144 13.7534C10.246 13.7456 11.0226 13.7456 11.8071 13.7534C12.2308 13.7534 12.5054 14.0117 12.5054 14.3797C12.5054 14.7476 12.2308 14.9902 11.7993 14.998C11.407 15.0059 11.0148 14.998 10.6225 14.998Z"
                      fill="#FF7A00"/>
                  </g>
                  <defs>
                    <clipPath id="clip0_121_11035">
                      <rect width="15" height="20" fill="white"/>
                    </clipPath>
                  </defs>
                </svg>
                <div class="input-title-item"> {{ $t('vendor-specifications') }}</div>
              </div>

              <div class="input-item">
                <div class="row" v-for="item in product.specifications">
                  <div class="col-md-6 col-12 input-item ">
                    <Input v-model="item.title"
                           :content="item.title"
                           :inputtype="'text'"
                           :label="$t('enter-specifications')"
                           :error="fieldErrors.includes('specifications')"
                    />
                  </div>
                  <div class="col-md-6 col-12   input-item">
                    <Input v-model="item.value"
                           :content="item.value"
                           :inputtype="'text'"
                           :label="$t('enter-amount')"
                           :error="fieldErrors.includes('specifications')"
                    />
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-6 input-item">
                    <Button @click='addSpecification()' :theme="'solid-grey'" :size="'small'"
                            :text="$t('add-specification')" :svg="'plus'"/>
                  </div>
                </div>
              </div>

            </div>

            <div class="col-md-12 input-item">
              <div class="d-flex align-items-center">
                <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M15.1998 9.4398L13.0837 7.91622C11.9841 7.12453 10.4814 7.20635 9.4743 8.11275L6.52531 10.7669C5.51819 11.6733 4.01547 11.7551 2.91589 10.9634L0.799805 9.4398M3.6798 15.1998H12.3198C13.9104 15.1998 15.1998 13.9104 15.1998 12.3198V3.6798C15.1998 2.08922 13.9104 0.799805 12.3198 0.799805H3.6798C2.08922 0.799805 0.799805 2.08922 0.799805 3.6798V12.3198C0.799805 13.9104 2.08922 15.1998 3.6798 15.1998ZM7.2798 5.4798C7.2798 6.47392 6.47392 7.2798 5.4798 7.2798C4.48569 7.2798 3.6798 6.47392 3.6798 5.4798C3.6798 4.48569 4.48569 3.6798 5.4798 3.6798C6.47392 3.6798 7.2798 4.48569 7.2798 5.4798Z"
                    stroke="#FF7A00" stroke-width="1.5" stroke-linecap="round"/>
                </svg>
                <div class="input-title-item"> {{ $t('vendor-add-photos') }}</div>
              </div>

              <div class="input-description">
                {{ $t('vendor-photos-description') }}
              </div>
              <div class="">
                <FileInput
                  :label="$t('choose-or-drag-a-file-here')"
                  :error="fieldErrors.includes('images')"
                  @change="e => uploadPhoto(e)"
                />
              </div>
              <div class="gallery-thumbnails row">
                <div v-for="image in product.images" class="col-md-4 col-6 thumb-frame">
                  <div class="thumb-image" v-bind:style="{ 'background-image': 'url(/storage/' + image.src + ')' }">
                  </div>
                  <div class=" x-icon">
                    <xIcon/>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-4 add-property-right">
          <div class="add-property-right-item">


            <div class="ad-update-container row">
              <div class="pb-2">
                <div class="date-added" v-if="product.id">
                  {{ $t('added-on') }}: {{ product.createdAt }}
                </div>
              </div>
              <div class="" v-if="product.id">
                {{ $t('product-id') }}: {{ product.id }}
              </div>
              <div class="mb-2 mt-4 d-flex">
                <span class="pe-3">{{ $t('public') }}:</span>
                <ToggleSwitch class="toggle-switch ms-auto" @change="handleStatusSwitch" :checked="product.status"/>
              </div>
              <div class="mt-2 mb-2">
                <div class="">
                  <div>{{ $t('product-item-number') }}:</div>
                  <Input
                    v-model="product.sku"
                    :content="product.sku"
                    v-bind:label="$t('input-number')"
                    :inputtype="'text'"
                    :error="fieldErrors.includes('sku')"
                  />
                </div>
              </div>
              <div class="d-flex">
                <div class="col-md-6 pe-2 ">
                  <div>{{ $t('price') }} <br> ({{ $t('bgn-full') }}):</div>
                  <Input
                    v-model="product.price"
                    :content="product.price"
                    v-bind:label="$t('input-price')"
                    :inputtype="'text'"
                    :error="fieldErrors.includes('price')"
                  />
                </div>
                <div class="col-md-6 ps-2 ">
                  <div>{{ $t('promo-price') }} <br> ({{ $t('bgn-full') }}):</div>
                  <Input v-model="product.promoPrice" :content="product.promoPrice" :label="$t('input-price')"
                         :inputtype="'text'"/>
                </div>
              </div>
              <div class="pt-4">
                <Button :size="'medium'" :theme="'solid-green'" :text="$t('save')" @click="saveProduct()"/>
              </div>
            </div>
          </div>
          <div class="add-property-right-item">
            <MakePremium
              :vip-end-date="product.vipEndDate && product.vipEndDate ? product.vipEndDate.date : nulll"
              v-model="selectedPlan"
              :activate="saveProduct"/>
          </div>
          <div v-bind:class="{'product-checkbox-wrapper': true, 'category-error': fieldErrors.includes('categories')}"
               v-if="selectedCategories">
            <div class="input-title-item"> {{ $t('choose-category') }}</div>
            <div class="d-flex checkbox-item" v-for="(item, key) in categories">
              <CheckBox @onChecked="handleCategoryChecked"
                        :value="item.id"
                        :checked="selectedCategories.includes(parseInt(item.id))"/>
              <div class="checkbox-label">{{ $t(item.title) }}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
