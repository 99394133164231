import Button from '@/components/Common/Buttons/Button/Button';
import Input from '@/components/Common/Inputs/Input/Input';
import xIcon from '@/components/Common/Icons/xIcon';
import FileInput from '@/components/Common/Inputs/FileInput/FileInput';
import TextArea from '@/components/Common/Inputs/TextArea/TextArea';
import Dropdown from '@/components/Common/Inputs/Dropdown/Dropdown';
import CheckBox from '@/components/Common/Inputs/CheckBox/CheckBox';
import YesNoInput from '@/components/Common/Inputs/YesNoInput/YesNoInput';
import MakePremium from '@/components/Components/MakePremium/MakePremium/';
import PremiumAdSelection from '@/components/Components/PremiumAdSelection/PremiumAdSelection/';
import CreditWallet from '@/components/Components/CreditWallet/CreditWallet/';
import AdUpdate from '@/components/Components/AdUpdate/AdUpdate';
import UserTypeMobile from '@/components/Header/HeaderMobile/UserTypeMobile/UserTypeMobile';
import UserNavMobile from '@/components/Header/HeaderMobile/UserNavMobile/UserNavMobile';
import FileService from '@/services/FileService';
import ToggleSwitch from "@/components/Common/Buttons/ToggleSwitch/ToggleSwitch";
import PagePreloader from "@/components/Components/PagePreloader/PagePreloader.vue";


export default {
    name: 'AdminAddProduct',
    components: {
        PagePreloader,
        ToggleSwitch,
        Button,
        CheckBox,
        MakePremium,
        AdUpdate,
        PremiumAdSelection,
        CreditWallet,
        TextArea,
        Dropdown,
        Input,
        UserTypeMobile,
        UserNavMobile,
        YesNoInput,
        FileInput,
        xIcon,
    },
    data() {
        return {
            isLoading: false,
            categoriesMenuActive: false,
            specificationKey: '',
            specificationValue: null,
            categories: [],
            fieldErrors: [],
            product: {
                sku: null,
                price: null,
                promoPrice: null,
                title: '',
                description: '',
                shortDescription: '',
                categories: [],
                specifications: [
                    {title: '', value: ''}
                ],
                images: [],
                status: null,
            },
            selectedCategories: [],
            selectedPlan: null,
        };
    },
    async mounted() {
        if (!this.$store.state.user.authenticated) {
            await this.$store.dispatch('utils/openModal', 'login');
            return;
        }

        if (this.$store.state.user.info.type !== 'vendor') {
            this.$router.push({name: 'AgencyDashboard'});
            return;
        }

        this.isLoading = true;
        const id = this.$route.params.id;
        this.categories = await this.$store.dispatch('product/getProductCategories');
        if (id) {
            this.product = await this.$store.dispatch('product/getProduct', id);


            const user = await this.$store.dispatch("user/loadAuthenticatedUser");
            if (this.product.vendor.id !== user.vendor.id) {
                this.$router.push({name: 'NotFoundPage'})
            }

            this.selectedCategories = this.product.categories.map((item) => {
                return parseInt(item.id);
            });
        }
        this.isLoading = false;
    },
    methods: {
        async uploadPhoto(event) {
            const file = event.target.files[0];
            const formData = new FormData();
            formData.append('file', file, file.name);
            const image = await FileService.uploadFile(formData);
            this.product.images.push(image);
            // this.$forceUpdate(this.product);
        },
        transformProductForSaving() {
            let product = this.product;
            product.status = this.product.status === true ? 1 : 0;
            product.specifications = product.specifications.filter(
                spec => spec.title.length > 0 && spec.value.length > 0
            );
            product.categories = this.selectedCategories;
            product.plan = this.selectedPlan;

            return product;
        },
        setFieldsErrors(err) {
            let vm = this;
            vm.fieldErrors = [];
            err.response.data.errors.forEach((item) => {
                vm.fieldErrors.push(item.property);
            });
        },
        async saveProduct() {
            await this.$store.dispatch('utils/openNotificationModal', {
                message: "Запазване на продукта ...",
                type: 'loading'
            });

            this.fieldErrors = [];

            try {
                const action = typeof this.product.id !== 'undefined' && this.product.id !== null
                    ? 'product/updateProduct' : 'product/createProduct';
                const savedProduct = await this.$store.dispatch(action, this.transformProductForSaving());
                this.$router.push({name: 'UserEditProduct', params: {id: savedProduct.id}})
                await this.$store.dispatch('utils/openNotificationModal', {
                    message: "Промените бяха запазен успешно",
                    type: 'success'
                });
                this.product = await this.$store.dispatch('product/getProduct', savedProduct.id);
            } catch (err) {
                if ('response' in err) {
                    switch (err.response.data.message) {
                        case 'validation_failed':
                            this.setFieldsErrors(err)
                            await this.$store.dispatch('utils/openNotificationModal', {
                                message: this.$t('error-please-fulfill-all-fields-with-valid-data'),
                                type: 'error'
                            });
                            break;
                        default:
                            await this.$store.dispatch('utils/openNotificationModal', {
                                message: this.$t(err.response.data.message),
                                type: 'error'
                            });
                            break;
                    }
                }
            }
        },
        handleCategoryChecked(event) {
            var index = this.selectedCategories.indexOf(event.value);
            if (index !== -1) {
                this.selectedCategories.splice(index, 1);
            }

            if (event.checked) {
                this.selectedCategories.push(event.value)
            }

            console.log(this.selectedCategories);
        },
        addSpecification() {
            this.product.specifications.push({title: '', value: ''});
        },
        handleStatusSwitch(e) {
            this.product.status = e.target.checked
        }
    },
};
